import React, { Component } from 'react';

import styles from './Tooltip.module.scss';

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayTooltip: false,
    };
  }

  hideTooltip = () => {
    this.setState({ displayTooltip: false });
  };

  showTooltip = () => {
    this.setState({ displayTooltip: true });
  };

  render() {
    const message = this.props.message;
    const position = this.props.position;

    console.log(message);

    return (
      <span style={this.props.wrapStyle} className={styles.tooltip} onMouseLeave={this.hideTooltip}>
        {this.state.displayTooltip && (
          <div
            className={`${styles.tooltip_bubble} ${styles[`tooltip_${position}`]}`}
            style={this.props.style}
          >
            <div
              className={styles.tooltip_message}
              dangerouslySetInnerHTML={{ __html: message }} // 계약서 작성 페이지 내 기타 계약 사항 툴팁에 볼드 디자인 적용 추가
              style={{ ...(this.props.msgStyle || {}), textAlign: 'left', fontSize: '11px' }}
            >
              {/* {message} */}
            </div>
            <div
              className={`${styles.triangle} ${styles[`triangle_${position}`]}`}
              style={this.props.triangle ? this.props.triangle : {}}
            />
          </div>
        )}
        <span
          style={this.props.childrenWrapStyle}
          className={styles.tooltip_trigger}
          onMouseOver={this.showTooltip}
          onFocus={() => {}}
        >
          {this.props.children}
        </span>
      </span>
    );
  }
}

export default Tooltip;
