import { COMPREHENSIVE_INSURANCE_INITIAL_DATA } from 'constants/comprehensiveInsuranceInfo';
import {
  INITIAL_DAILY_PAYMENT,
  INITIAL_LONG_TERM_PAYMENT,
  INITIAL_MONTHLY_PAYMENT,
} from 'constants/normalContractPaymentInfo';
import dayjs from 'dayjs';
import dateTimeParser from 'helpers/dateTimeParser';
import { RentType } from 'interface/normalContract';
import { makeAutoObservable, toJS } from 'mobx';
import { _setObject, ClassVariableType } from 'stores/BaseStore.type';

import type {
  ComprehensiveInsuranceInfoType,
  DailyPayment,
  LongTermPayment,
  MonthlyPayment,
  PaymentInfo,
} from './types';

class NormalContractStore {
  id = null;
  request_id = null;
  orderer = null;
  isIsland = null;
  isJeju = null;
  additional = false;
  customer_name = null;
  customer_driver_license = null;
  customer_id_number = null;
  customer_id_number1 = null;
  customer_id_number2 = null;
  customer_contact = null;
  customer_driver_type = null;
  customer_license_limit = null;
  customer_license_date = null;
  customer_address = null;
  customer_id = null;
  second_driver_name = null;
  second_driver_contact = null;
  second_driver_license = null;
  second_driver_type = null;
  second_driver_license_limit = null;
  second_driver_license_date = null;
  second_driver_address = null;
  second_driver_id = null;
  second_driver_id_number1 = null;
  second_driver_id_number2 = null;
  rent_car_name = null;
  rent_car_number = null;
  driven_distance = null;
  delivery = true;
  self_car_insurance = true;
  self_car_insurance_limit = 3000;
  indemnfication_fee = 50 || '50';
  indemnfication_fee_first = null;
  indemnfication_fee_second = null;
  self_car_indemnfication_fee = 50;
  oil_type = null;
  gas_charge = 25;
  rent_company_car_id = null;
  has_second_driver = false;
  indemnfication_fee_button_type = true;
  // delivered_date = null;  // TODO: 결제정보 리팩토링
  // delivered_hour = null;    // TODO: 결제정보 리팩토링
  // delivered_minute = null;   // TODO: 결제정보 리팩토링
  // expect_return_date = null;   // TODO: 결제정보 리팩토링
  // expect_return_hour = null;   // TODO: 결제정보 리팩토링
  // expect_return_minute = null;  // TODO: 결제정보 리팩토링
  partner_info = null;
  sales_employee = null;
  memo = null;
  customer_memo = null;
  driver_memo = null;
  second_driver_memo = null;
  self_critical_indemnification_fee = null; // 자손면책금(사망,후유장애)
  isLicenseSerarched = false;
  license_certificated = false;
  license_certificated_code = null;
  isSecondLicenseSerarched = false;
  second_driver_license_certificated = false;
  second_driver_license_certificated_code = null;
  // total_cost = null; // TODO: 결제정보 리팩토링
  // security_deposit = null; TODO: 결제정보 리팩토링
  // payment_memo = null; // TODO: 결제정보 리팩토링
  insurance_age = 26;
  person_limit_select_type = null;
  object_limit_select_type = null;

  // 종합 보험 정보
  /** 대인 한도 */
  insurance_person_limit: number = COMPREHENSIVE_INSURANCE_INITIAL_DATA.INSURANCE_PERSON_LIMIT;
  /** 대인 면책금 */
  people_indemnfication_fee: number | null =
    COMPREHENSIVE_INSURANCE_INITIAL_DATA.PEOPLE_INDEMNFICATION_FEE;
  /** 대물 한도 */
  insurance_object_limit: number = COMPREHENSIVE_INSURANCE_INITIAL_DATA.INSURANCE_OBJECT_LIMIT;
  /** 대물 면책금 */
  object_indemnfication_fee: number | null =
    COMPREHENSIVE_INSURANCE_INITIAL_DATA.OBJECT_INDEMNFICATION_FEE;
  /** 자손 한도 (부상) */
  insurance_self_limit: number = COMPREHENSIVE_INSURANCE_INITIAL_DATA.INSURANCE_SELF_LIMIT;
  /** 자손 한도 (사망) */
  insurance_self_dead_limit: number =
    COMPREHENSIVE_INSURANCE_INITIAL_DATA.INSURANCE_SELF_DEAD_LIMIT;
  /** 자손 면책금 */
  self_indemnification_fee: number | null =
    COMPREHENSIVE_INSURANCE_INITIAL_DATA.SELF_INDEMNIFICATION_FEE;
  /** input 노출 여부 */
  isExistComprehensiveInsuranceData = false;

  self_limit_select_type = true;
  version = null;
  // 추가
  // alimtalk = false; TODO: 결제정보 리팩토링
  // payment_method_type = null; TODO: 결제정보 리팩토링
  // prepayment_term  = null; TODO: 결제정보 리팩토링
  // prepaid_amount = null; TODO: 결제정보 리팩토링
  // deposit_amount = null; TODO: 결제정보 리팩토링
  // monthly_fee = null; TODO: 결제정보 리팩토링
  // payment_day = null; TODO: 결제정보 리팩토링
  // bank_name = null; //  은행명 TODO: 결제정보 리팩토링
  // account_num = null; // 계좌번호 TODO: 결제정보 리팩토링
  // account_holder = null; // 예금주 TODO: 결제정보 리팩토링
  // initial_payment_date = null; // 최초납부일 TODO: 결제정보 리팩토링
  // payment_method_detail = null; // 납부방법 기타의 상세 TODO: 결제정보 리팩토링
  indemnfication_fee1 = null;
  indemnfication_fee2 = null;
  attachments = null;
  start_datetime = null;
  start_datetime_hour = null;
  start_datetime_minute = null;
  end_datetime = null;
  end_datetime_hour = null;
  end_datetime_minute = null;
  duplicatedContractList = [];
  /** 고객예약일시 */
  reservation_date = null;
  // dropoff_extend = null; TODO: 결제정보 리팩토링
  dropoff_address = null;
  fuelRemainPct = null;
  totalMileage = null;
  use_connect = null;
  // 차 교체 계약서 반납정보
  return_datetime = null;
  return_gas_charge = null;
  driven_distance_upon_return = null;
  fuel_cost = null;
  is_corporation = false;
  corporation_name = null;
  corporation_business_number = null;
  corporation_number1 = null;
  corporation_number2 = null;
  corporation_address = null;
  corporation_manager_name = null;
  corporation_manager_contact = null;
  // longTermMonth = 12; TODO: 결제정보 리팩토링

  is_send_customer_message = false; // 개인 알림톡 발송 여부
  is_send_manager_message = false; // 법인 알림톡 발송 여부
  is_send_recommender_message = false; // 소개처 알림톡 발송 여부

  use_self_car_insurance_percentage = false;
  schedule_id = null; // 연결할 예약건 스케줄 id
  reservation_id = null; // 예약 id

  isExistSelfCarInsuranceData = false;
  car_group_id = null;
  self_car_insurance_datas = null;
  kakao_version = null;
  created_at = null;
  rent_type: RentType = 'daily';
  // 결제 정보
  paymentInfo: PaymentInfo = INITIAL_DAILY_PAYMENT;

  /** computed variable
   *  - isValidPrepaidAmount [장기렌트 선수금 매출 처리 Radio 버튼 disable 체크를 위한 vaildator]
   *  - longTermTotalPrice [장기렌트 총 요금 계산]
   */
  isSameAsFirstDriverAddress = false; // 제2운전자 주소가 제1운전자와 동일함 (체크박스)
  is_camping_car = false; // car_segment: suv, size: H 이면 캠핑카 또는 segment_id가 28 또는 29
  self_car_insurance_type: 'general' | 'premium' | 'super'; // 자자보험 종류. 일반: general, 고급: premium, 완전자차: super. 완전자차는 사용하는 곳 없음

  constructor() {
    makeAutoObservable(this);
  }

  isLongTermPayment = (paymentInfo): paymentInfo is LongTermPayment => {
    return paymentInfo.rent_type === 'long_term';
  };

  setRentType = (rent_type: RentType) => {
    this.paymentInfo = { rent_type } as PaymentInfo; // 날짜 및 기타 정보가 리셋 되어야 함
    this.rent_type = this.paymentInfo.rent_type;

    if (rent_type === 'long_term') {
      this.paymentInfo = INITIAL_LONG_TERM_PAYMENT;
    } else if (rent_type === 'monthly') {
      this.paymentInfo = INITIAL_MONTHLY_PAYMENT;
    } else {
      this.paymentInfo = INITIAL_DAILY_PAYMENT;
    }
  };

  // 사용되는 곳이 없는 듯 함
  // getaymentInfo() {
  //   if (this.paymentInfo.rent_type === 'daily') {
  //     return this.paymentInfo;
  //   }

  //   if (this.paymentInfo.rent_type === 'monthly') {
  //     return this.paymentInfo;
  //   }

  //   if (this.paymentInfo.rent_type === 'long_term') {
  //     return this.paymentInfo;
  //   }
  // }

  setPaymentInfo = (data: Partial<PaymentInfo>) => {
    this.paymentInfo = {
      ...this.paymentInfo,
      ...data,
    } as PaymentInfo;

    return this.paymentInfo;
  };

  get isValidPrepaidAmount() {
    // 장기렌트 선수금 매출 처리 Radio 버튼 disable 체크를 위한 vaildator
    if (this.paymentInfo.rent_type === 'long_term') {
      const {
        monthly_fee,
        prepaid_amount,
        delivered_date,
        delivered_hour,
        delivered_minute,
        expect_return_date,
        expect_return_hour,
        expect_return_minute,
      } = this.paymentInfo;

      if (
        monthly_fee &&
        prepaid_amount &&
        delivered_date &&
        delivered_hour &&
        delivered_minute &&
        expect_return_date &&
        expect_return_hour &&
        expect_return_minute
      ) {
        return false;
      }

      if (
        this.paymentInfo.prepayment_term !== null ||
        this.paymentInfo.prepayment_term !== 'null'
      ) {
        this.paymentInfo.prepayment_term = null;
      }

      return true;
    }

    return true;
  }

  get longTermTotalPrice() {
    // 장기렌트 총 요금 계산
    if (this.paymentInfo.rent_type === 'long_term') {
      return (
        Number(this.paymentInfo.prepaid_amount) +
        Number(this.paymentInfo.monthly_fee) * Number(this.paymentInfo.longTermMonth)
      );
    }

    // return this.total_cost;
    return this.paymentInfo.total_cost;
  }

  resetPaymentInfoDate = () => {
    this.setPaymentInfo({
      expect_return_date: null,
      expect_return_hour: null,
      expect_return_minute: null,
      delivered_date: null,
      delivered_hour: null,
      delivered_minute: null,
    });
  };

  // 장기렌트 계약일시 변경시 계약종료일 자동 입력
  // reaction으로 처리 가능.
  setPaymentInfoLongTermExpectReturnDate = () => {
    if (this.paymentInfo.rent_type === 'long_term' && this.paymentInfo.longTermMonth) {
      this.setPaymentInfo({
        expect_return_date: dateTimeParser({
          date: dayjs(this.paymentInfo.delivered_date).add(this.paymentInfo.longTermMonth, 'month'),
          format: 'YYYY-MM-DD',
        }),
        expect_return_hour: this.paymentInfo.delivered_hour,
        expect_return_minute: this.paymentInfo.delivered_minute,
      });
    }
  };

  //배차일시 선택완료시 반납 예정일 자동 set 함수
  addDeliveredDates = (day = 1) => {
    if (
      this.paymentInfo.delivered_date === null ||
      this.paymentInfo.delivered_hour === null ||
      this.paymentInfo.delivered_minute === null
    ) {
      return;
    }

    this.setPaymentInfo({
      expect_return_date: dateTimeParser({
        date: dayjs(this.paymentInfo.delivered_date).add(day, 'day'),
        format: 'YYYY-MM-DD',
      }),
      expect_return_hour: this.paymentInfo.delivered_hour,
      expect_return_minute: this.paymentInfo.delivered_minute,
    });
  };

  clear = () => {
    const resetedObject = Object.fromEntries(
      Object.entries(toJS(new NormalContractStore())).filter(
        ([key, value]) => typeof value !== 'function'
      )
    ) as ClassVariableType<NormalContractStore>;

    this.setObject(resetedObject);

    return this;
  };

  setValue = (data, callback?: () => void) => {
    this[data.key] = data.value === '' ? null : data.value;

    if (callback) {
      callback();
    }
  };

  setObject = (data) => _setObject(this, data);

  hasSecondDriver = () => {
    this.has_second_driver = !this.has_second_driver;
  };
  // getContractData.  사용하지 않는 함수로 보여짐
  getContractData = () => {
    return {
      request_id: this.request_id,
      customer_name: this.customer_name,
      customer_contact: this.customer_contact,
      customer_address: this.customer_address,
      customer_id_number: this.customer_id_number,
      customer_id_number1: this.customer_id_number1,
      customer_id_number2: this.customer_id_number2,
      delivery: this.delivery,
      customer_driver_license: this.customer_driver_license,
      customer_driver_type: this.customer_driver_type,
      customer_license_limit: this.customer_license_limit,
      customer_license_date: this.customer_license_date,
      second_driver_name: this.second_driver_name,
      second_driver_contact: this.second_driver_contact,
      second_driver_address: this.second_driver_address,
      second_driver_id: this.second_driver_id,
      second_driver_id_number1: this.second_driver_id_number1,
      second_driver_id_number2: this.second_driver_id_number2,
      second_driver_license: this.second_driver_license,
      second_driver_type: this.second_driver_type,
      second_driver_license_limit: this.second_driver_license_limit,
      second_driver_license_date: this.second_driver_license_date,
      rent_car_name: this.rent_car_name,
      rent_car_number: this.rent_car_number,
      self_car_insurance: this.self_car_insurance,
      indemnfication_fee: this.indemnfication_fee,
      indemnfication_fee_first: this.indemnfication_fee_first,
      indemnfication_fee_second: this.indemnfication_fee_second,
      self_car_indemnfication_fee: this.self_car_indemnfication_fee,
      driven_distance: this.driven_distance,
      oil_type: this.oil_type,
      gas_charge: this.gas_charge,
      // delivered_date: this.delivered_date,
      // delivered_hour: this.delivered_hour,
      // delivered_minute: this.delivered_minute,
      // expect_return_date: this.expect_return_date,
      // expect_return_hour: this.expect_return_hour,
      // expect_return_minute: this.expect_return_minute,
      memo: this.memo,
      customer_memo: this.customer_memo,
      people_indemnfication_fee: this.people_indemnfication_fee,
      object_indemnfication_fee: this.object_indemnfication_fee,
      self_indemnification_fee: this.self_indemnification_fee,
      self_critical_indemnification_fee: this.self_critical_indemnification_fee,
      // total_cost: this.total_cost,
      // security_deposit: this.security_deposit,
      // payment_memo: this.payment_memo,
      rent_type: this.paymentInfo.rent_type,
      insurance_age: this.insurance_age,
      insurance_person_limit: this.insurance_person_limit,
      insurance_object_limit: this.insurance_object_limit,
      insurance_self_limit: this.insurance_self_limit,
      insurance_self_dead_limit: this.insurance_self_dead_limit,
      return_datetime: this.return_datetime,
      return_gas_charge: this.return_gas_charge,
      driven_distance_upon_return: this.driven_distance_upon_return,
      fuel_cost: this.fuel_cost,
      is_corporation: this.is_corporation,
      corporation_name: this.corporation_name,
      corporation_business_number: this.corporation_business_number,
      corporation_number1: this.corporation_number1,
      corporation_number2: this.corporation_number2,
      corporation_address: this.corporation_address,
      corporation_manager_name: this.corporation_manager_name,
      self_car_insurance_limit: this.self_car_insurance_limit,
      // longTermMonth: this.longTermMonth,
      reservation_date: this.reservation_date,
      // dropoff_extend: this.dropoff_extend,
      dropoff_address: this.dropoff_address,
      fuelRemainPct: this.fuelRemainPct,
      totalMileage: this.totalMileage,
      use_connect: this.use_connect,

      use_self_car_insurance_percentage: (this.use_self_car_insurance_percentage = false),
      schedule_id: this.schedule_id,
      reservation_id: this.reservation_id,
    };
  };

  initCorporationInfo = () => {
    this.corporation_name = null;
    this.corporation_business_number = null;
    this.corporation_number1 = null;
    this.corporation_number2 = null;
    this.corporation_address = null;
    this.corporation_manager_name = null;
    this.corporation_manager_contact = null;
  };

  getSubmitData = () => {
    let secondDriver = {
      second_driver_name: undefined,
      second_driver_contact: undefined,
      second_driver_address: undefined,
      second_driver_id_number1: undefined,
      second_driver_id_number2: undefined,
      second_driver_license: undefined,
      second_driver_type: undefined,
      second_driver_license_limit: undefined,
      second_driver_license_date: undefined,
      second_driver_license_certificated: undefined,
      second_driver_license_certificated_code: undefined,
    };
    const basicInfo = {
      request_id: this.request_id,
    };
    const driverInfo = {
      customer_name: this.customer_name,
      customer_contact: this.customer_contact,
      customer_address: this.customer_address,
      customer_id_number1: this.customer_id_number1,
      customer_id_number2: this.customer_id_number2,
      customer_driver_license: this.customer_driver_license,
      customer_driver_type: this.customer_driver_type,
      customer_license_limit: this.customer_license_limit,
      customer_license_date: this.customer_license_date,
      is_corporation: this.is_corporation,
      license_certificated: undefined,
      license_certificated_code: undefined,
    };
    let corporationInfo = {};

    if (this.is_corporation) {
      corporationInfo = {
        corporation_name: this.corporation_name,
        corporation_business_number: this.corporation_business_number,
        corporation_number: this.corporation_number1
          ? `${this.corporation_number1}-${this.corporation_number2}`
          : null,
        corporation_address: this.corporation_address,
        corporation_manager_name: this.corporation_manager_name,
        corporation_manager_contact: this.corporation_manager_contact,
      };
    }

    const rentInfo = {
      rent_car_name: this.rent_car_name,
      rent_car_number: this.rent_car_number,
      oil_type: this.oil_type,
      gas_charge: this.gas_charge,
      rent_company_car_id: this.rent_company_car_id,
      driven_distance: this.driven_distance,
      fuelRemainPct: this.fuelRemainPct,
      totalMileage: this.totalMileage,
      dropoff_address: this.dropoff_address,
      use_connect: this.use_connect,
    };

    const insuranceInfo = {
      // 자차보험 정보
      insurance_age: this.insurance_age,
      self_car_insurance: this.self_car_insurance,
      self_car_insurance_limit: this.self_car_insurance_limit,
      indemnfication_fee: this.indemnfication_fee,
      self_car_indemnfication_fee: this.self_car_indemnfication_fee,
      // 종합보험 정보
      people_indemnfication_fee: this.people_indemnfication_fee,
      object_indemnfication_fee: this.object_indemnfication_fee,
      self_critical_indemnification_fee: this.self_critical_indemnification_fee,
      self_indemnification_fee: this.self_indemnification_fee,
      insurance_person_limit: this.insurance_person_limit,
      insurance_object_limit: this.insurance_object_limit,
      insurance_self_limit: this.insurance_self_limit,
      insurance_self_dead_limit: this.insurance_self_dead_limit,
    };

    // const paymentInfo = {
    //   rent_type: this.rent_type,
    //   delivered_date: `${this.delivered_date}-${this.delivered_hour}-${this.delivered_minute}-0`,
    //   expect_return_date: `${this.expect_return_date}-${this.expect_return_hour}-${this.expect_return_minute}-0`,
    //   total_cost: this.total_cost || 0,
    //   security_deposit: this.security_deposit,
    //   payment_memo: this.payment_memo,

    //   alimtalk: this.alimtalk, // 알림톡
    //   payment_method_type: this.payment_method_type, // 납부방법
    //   prepayment_term: this.prepayment_term === 'null' ? null : this.prepayment_term, // 선수금매출처리
    //   prepaid_amount: this.prepaid_amount, // 선수금
    //   deposit_amount: this.deposit_amount, // 보증금
    //   monthly_fee: this.monthly_fee, // 월 납부금
    //   payment_day: this.payment_day, // 납부일
    //   initial_payment_date: this.initial_payment_date, // 최초 납부일
    //   bank_name: this.bank_name, //  은행명
    //   account_num: this.account_num, // 계좌번호
    //   account_holder: this.account_holder, // 예금주
    //   payment_method_detail: this.payment_method_detail, // 납부방법 기타의 상세
    //   dropoff_extend: this.dropoff_extend,

    //   start_datetime: undefined,
    //   end_datetime: undefined,
    // };

    const paymentInfo = {
      ...this.paymentInfo,
      delivered_date: `${this.paymentInfo.delivered_date}-${this.paymentInfo.delivered_hour}-${this.paymentInfo.delivered_minute}-0`,
      expect_return_date: `${this.paymentInfo.expect_return_date}-${this.paymentInfo.expect_return_hour}-${this.paymentInfo.expect_return_minute}-0`,
      total_cost: this.paymentInfo.total_cost || 0,

      // prepayment_term:
      //   this.paymentInfo.prepayment_term === 'null' ? null : this.paymentInfo.prepayment_term, // 선수금매출처리

      start_datetime: undefined,
      end_datetime: undefined,
    };

    // if (!this.isLongTermPayment(this.paymentInfo)) {
    //   delete paymentInfo.alimtalk;
    //   delete paymentInfo.payment_method_type;
    //   delete paymentInfo.prepayment_term;
    //   delete paymentInfo.prepaid_amount;
    //   delete paymentInfo.deposit_amount;
    //   delete paymentInfo.monthly_fee;
    //   delete paymentInfo.payment_day;
    //   delete paymentInfo.initial_payment_date;
    //   delete paymentInfo.bank_name;
    //   delete paymentInfo.account_num;
    //   delete paymentInfo.account_holder;
    // }

    if (this.isLongTermPayment(paymentInfo) && this.isLongTermPayment(this.paymentInfo)) {
      if (this.paymentInfo.payment_method_type !== '계좌이체') {
        delete paymentInfo.bank_name;
        delete paymentInfo.account_num;
        delete paymentInfo.account_holder;
      }

      if (this.paymentInfo.payment_method_type === '카드결제') {
        delete paymentInfo.payment_method_detail;
      }

      paymentInfo.prepayment_term =
        this.paymentInfo.prepayment_term === 'null' ? null : this.paymentInfo.prepayment_term; // 선수금 매출 처리
      paymentInfo.start_datetime = `${this.paymentInfo.delivered_date} ${this.paymentInfo.delivered_hour}-${this.paymentInfo.delivered_minute}`;
      paymentInfo.delivered_date = `${this.paymentInfo.delivered_date} ${this.paymentInfo.delivered_hour}-${this.paymentInfo.delivered_minute}-0`;
      paymentInfo.end_datetime = `${this.paymentInfo.expect_return_date} ${this.paymentInfo.expect_return_hour}-${this.paymentInfo.expect_return_minute}`;
      paymentInfo.expect_return_date = `${this.paymentInfo.expect_return_date} ${this.paymentInfo.expect_return_hour}-${this.paymentInfo.expect_return_minute}-0`;
    }

    const etcInfo = {
      recommender_id: this.partner_info ? this.partner_info.id : null,
      sales_employee_id: this.sales_employee ? this.sales_employee.id : null,
      contract_memo: undefined,
      customer_memo: undefined,
    };

    if (this.memo) {
      if (this.memo.length != 0) {
        etcInfo.contract_memo = this.memo;
      }
    }

    if (this.customer_memo) {
      if (this.customer_memo.length != 0) {
        etcInfo.customer_memo = this.customer_memo;
      }
    }

    // 추가
    if (this.has_second_driver == true) {
      secondDriver = {
        ...secondDriver,
        second_driver_name: this.second_driver_name,
        second_driver_contact: this.second_driver_contact,
        second_driver_address: this.second_driver_address,
        second_driver_id_number1: this.second_driver_id_number1,
        second_driver_id_number2: this.second_driver_id_number2,
        second_driver_license: this.second_driver_license,
        second_driver_type: this.second_driver_type,
        second_driver_license_limit: this.second_driver_license_limit,
        second_driver_license_date: this.second_driver_license_date,
      };
    }

    if (this.license_certificated) {
      //진위여부 했을경우
      driverInfo.license_certificated = this.license_certificated;
      driverInfo.license_certificated_code = this.license_certificated_code;
    }

    if (this.second_driver_license_certificated) {
      //제 2운전자 진위여부 했을경우
      secondDriver.second_driver_license_certificated = this.second_driver_license_certificated;
      secondDriver.second_driver_license_certificated_code =
        this.second_driver_license_certificated_code;
    }

    // if (this.rent_type !== 'long_term') {
    //   delete paymentInfo.alimtalk;
    //   delete paymentInfo.payment_method_type;
    //   delete paymentInfo.prepayment_term;
    //   delete paymentInfo.prepaid_amount;
    //   delete paymentInfo.deposit_amount;
    //   delete paymentInfo.monthly_fee;
    //   delete paymentInfo.payment_day;
    //   delete paymentInfo.initial_payment_date;
    //   delete paymentInfo.bank_name;
    //   delete paymentInfo.account_num;
    //   delete paymentInfo.account_holder;
    // }

    // if (this.rent_type === 'long_term') {
    //   if (this.payment_method_type !== '계좌이체') {
    //     delete paymentInfo.bank_name;
    //     delete paymentInfo.account_num;
    //     delete paymentInfo.account_holder;
    //   } else if (this.payment_method_type === '기타') {
    //     delete paymentInfo.payment_method_detail;
    //   }

    //   paymentInfo.start_datetime = `${this.start_datetime} ${this.start_datetime_hour}-${this.start_datetime_minute}`;
    //   paymentInfo.delivered_date = `${this.start_datetime}-${this.start_datetime_hour}-${this.start_datetime_minute}-0`;
    //   paymentInfo.end_datetime = `${this.end_datetime} ${this.end_datetime_hour}-${this.end_datetime_minute}`;
    //   paymentInfo.expect_return_date = `${this.end_datetime}-${this.end_datetime_hour}-${this.end_datetime_minute}-0`;
    // }

    let data = {
      ...basicInfo,
      ...driverInfo,
      ...rentInfo,
      ...insuranceInfo,
      ...paymentInfo,
      ...etcInfo,
      ...secondDriver,
      // customer_id_number : this.customer_id_number,
      web_request: true,
      attachments: this.attachments && this.attachments.filter((item) => Boolean(item)),

      /**
       * 알림톡 발송 여부 정보. (개인, 법인, 소개처)
       */
      is_send_customer_message: this.is_send_customer_message,
      is_send_manager_message: this.is_send_manager_message,
      is_send_recommender_message: this.is_send_recommender_message,

      use_self_car_insurance_percentage: this.use_self_car_insurance_percentage,
      schedule_id: this.schedule_id,
      reservation_id: this.reservation_id,
      additional: undefined,
      normal_contract_id: undefined,
      return_datetime: undefined,
      return_gas_charge: undefined,
      driven_distance_upon_return: undefined,
      fuel_cost: undefined,
    };

    if (!this.self_car_insurance) {
      this.indemnfication_fee = null;
      this.self_car_insurance_limit = null;
      data.indemnfication_fee = null;
      data.self_car_insurance_limit = null;
    }

    if (this.self_car_insurance) {
      // 자차 면책금 설정 range 두 Input-> 단일 Input으로 변경됨에따라 주석처리
      // if (!!this.indemnfication_fee1 && !!this.indemnfication_fee2) {
      //   data.indemnfication_fee = `${this.indemnfication_fee1}~${this.indemnfication_fee2}`;
      // }
      if (!this.indemnfication_fee1 && !this.indemnfication_fee2 && this.indemnfication_fee) {
        data.indemnfication_fee = this.indemnfication_fee;
      }
    }

    if (driverInfo.is_corporation) {
      data = { ...data, ...corporationInfo };
    }

    if (this.additional == true) {
      data.additional = this.additional;
      data.normal_contract_id = this.id;
      data.return_datetime = this.return_datetime;
      data.return_gas_charge = this.return_gas_charge;
      data.driven_distance_upon_return = this.driven_distance_upon_return;
      data.fuel_cost = this.fuel_cost;
    }

    return data;
  };

  setComprehensiveInsuranceInfo = ({
    insurance_person_limit,
    people_indemnfication_fee,
    insurance_object_limit,
    object_indemnfication_fee,
    insurance_self_limit,
    insurance_self_dead_limit,
    self_indemnification_fee,
    isExistComprehensiveInsuranceData,
  }: ComprehensiveInsuranceInfoType) => {
    this.insurance_person_limit = insurance_person_limit;
    this.people_indemnfication_fee = people_indemnfication_fee;
    this.insurance_object_limit = insurance_object_limit;
    this.object_indemnfication_fee = object_indemnfication_fee;
    this.insurance_self_limit = insurance_self_limit;
    this.insurance_self_dead_limit = insurance_self_dead_limit;
    this.self_indemnification_fee = self_indemnification_fee;
    this.isExistComprehensiveInsuranceData = isExistComprehensiveInsuranceData;
  };

  initComprehensiveInsuranceInfo = () => {
    this.insurance_person_limit = COMPREHENSIVE_INSURANCE_INITIAL_DATA.INSURANCE_PERSON_LIMIT;
    this.people_indemnfication_fee = COMPREHENSIVE_INSURANCE_INITIAL_DATA.PEOPLE_INDEMNFICATION_FEE;
    this.insurance_object_limit = COMPREHENSIVE_INSURANCE_INITIAL_DATA.INSURANCE_OBJECT_LIMIT;
    this.object_indemnfication_fee = COMPREHENSIVE_INSURANCE_INITIAL_DATA.OBJECT_INDEMNFICATION_FEE;
    this.insurance_self_limit = COMPREHENSIVE_INSURANCE_INITIAL_DATA.INSURANCE_SELF_LIMIT;
    this.insurance_self_dead_limit = COMPREHENSIVE_INSURANCE_INITIAL_DATA.INSURANCE_SELF_DEAD_LIMIT;
    this.self_indemnification_fee = COMPREHENSIVE_INSURANCE_INITIAL_DATA.SELF_INDEMNIFICATION_FEE;
    this.isExistComprehensiveInsuranceData =
      COMPREHENSIVE_INSURANCE_INITIAL_DATA.IS_EXIST_COMPREHENSIVE_INSURANCE;
  };
}

export default NormalContractStore;
